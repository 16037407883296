<template>
  <div class="tooltip">
    <slot />
    <div class="tooltip__message" :class="direction">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    message: {
      type: String,
      required: true,
    },
    /* TODO: доработать другие направления */
    direction: {
      type: String,
      default: 'left',
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  &__message {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: rem(32);
    padding: 0 rem(11);
    color: #fff;
    font-size: rem(12);
    white-space: nowrap;
    background-color: $text-color;
    border-radius: rem(3);
    visibility: hidden;
    opacity: 0;
    transition: opacity $default-transition, visibility $default-transition;
    &:before {
      position: absolute;
      border: rem(6) solid transparent;
      border-right-color: $text-color;
      content: '';
    }
    &.left {
      left: 100%;
      margin: -25% 0 0 rem(12);
      &:before {
        left: 0;
        transform: translateX(-100%);
      }
    }
  }
  &:hover .tooltip__message {
    visibility: visible;
    opacity: 1;
  }
}
</style>
