<template>
  <div :class="['card', `card--${type}`]">
    <CLoader v-if="!imageLoaded && !isDefault" />
    <CTooltip message="В избранное" class="card__heart">
      <CIcon name="heart" />
    </CTooltip>

    <ProductCardSingle v-if="isDefault" />

    <ProductCardSlider
      v-if="isFulled"
      :images="images"
      @image-loaded="imageLoaded = $event"
    />

    <div v-once class="card__content">
      <h4 class="card__content-title">Букет из 21 розы</h4>
      <div class="card__rating">
        <div class="card__rating-active" :style="`width:${rating}%;`">
          <CIcon
            v-for="item in 5"
            :key="`star-active_${item}`"
            class="card__rating-icon"
            name="rating"
          />
        </div>
        <div class="card__rating-inactive">
          <CIcon
            v-for="item in 5"
            :key="`star-inactive_${item}`"
            class="card__rating-icon card__rating-icon--inactive"
            name="rating"
          />
        </div>
      </div>
      <div class="card__evals">(оценок: 6179)</div>
      <Price />
    </div>
  </div>
</template>

<script>
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import Price from '@/components/ui/Price'
import CIcon from '@/components/common/CIcon'
import CLoader from '@/components/common/CLoader'
import CTooltip from '@/components/common/CTooltip'

const TYPES = ['default', 'fulled']

const ProductCardSlider = import(
  /* webpackChunkName: "product-card-slider" */ '@/components/ProductCard/ProductCardSlider'
)
const ProductCardSingle = import(
  /* webpackChunkName: "product-card-single" */ '@/components/ProductCard/ProductCardSingle'
)

export default {
  name: 'ProductCard',

  components: {
    CTooltip,
    CLoader,
    CIcon,
    Price,
    ProductCardSlider: hydrateWhenVisible(() => ProductCardSlider),
    ProductCardSingle: hydrateWhenVisible(() => ProductCardSingle),
  },

  props: {
    type: {
      type: String,
      default: 'default',
      validator: (v) => TYPES.includes(v),
    },
  },

  data() {
    return {
      imageLoaded: false,
    }
  },

  computed: {
    isDefault() {
      const [defaultType] = TYPES
      return this.type === defaultType
    },
    isFulled() {
      const [, fulledType] = TYPES
      return this.type === fulledType
    },
  },

  created() {
    /* TODO: Удалить когда будут реальные данные */
    this.rating = 50
    this.images = [
      'https://flor2u.ru/images/uploads/conversion/9b9/9b991bf40895a4255515f44d9d11990b/9b991bf40895a4255515f44d9d11990b-flor.catalog.best.jpg',
      'https://flor2u.ru/images/uploads/conversion/041/041ec5baa68a27b6ca9dd883d6d0da72/041ec5baa68a27b6ca9dd883d6d0da72-flor.catalog.best.jpg',
      'https://flor2u.ru/images/uploads/conversion/b38/b38b767834fde405e7048a981fce5929/b38b767834fde405e7048a981fce5929-flor.catalog.best.jpg',
      'https://flor2u.ru/images/uploads/conversion/926/926ba45eb6f5397ddf72012c392648a7/926ba45eb6f5397ddf72012c392648a7-flor.catalog.best.jpg',
      'https://flor2u.ru/images/uploads/conversion/b85/b85f5bb028461222846973af0ae7c752/b85f5bb028461222846973af0ae7c752-flor.catalog.best.jpg',
      'https://flor2u.ru/images/uploads/conversion/857/8578361a1aba92b85e0e4916b44ad37e/8578361a1aba92b85e0e4916b44ad37e-flor.catalog.best.jpg',
      'https://flor2u.ru/images/uploads/conversion/f8a/f8a8a477c42b56999bd2d424e309b58d/f8a8a477c42b56999bd2d424e309b58d-flor.catalog.best.jpg',
    ]
  },
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: calc(25% - #{rem(18)});
  min-height: rem(365);
  margin: 0 rem(9) rem(18) rem(9);
  border-radius: rem(2);

  &:hover {
    .card__heart {
      visibility: visible;
      opacity: 1;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    min-height: rem(50);
    margin-top: auto;
    padding: rem(16) rem(4.5) rem(18) rem(4.5);
    background-color: rgba(233, 236, 245, 0.45);
    &-title {
      color: $text-color;
      font-weight: 700;
      text-align: center;
    }
  }
  &__heart {
    position: absolute;
    top: rem(10);
    right: rem(10);
    z-index: 2;
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    transition: opacity $default-transition, visibility $default-transition;
    fill: $color-pink;
    @include base-width(22);
  }
  &__rating {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    margin: rem(8) auto 0 auto;
    &-icon {
      height: rem(12);
      margin: 0 rem(1);
      fill: $color-pink;
      @include base-width(12);
      &--inactive {
        fill: $color-gray;
      }
    }
    &-active {
      z-index: 2;
      display: flex;
      margin-right: auto;
      overflow: hidden;
      white-space: nowrap;
    }
    &-inactive {
      z-index: 1;
      display: flex;
      margin: rem(-12) 0 0 0;
    }
  }
  &__evals {
    margin: rem(4) 0 0 0;
    font-size: rem(11);
    text-align: center;
  }

  &--default {
    padding: rem(24) 0 0 0;
    background-color: #fff;
    box-shadow: 0 rem(4) rem(14) rgba(36, 31, 33, 0);
    transition: box-shadow $default-transition;
    will-change: box-shadow;
    .card__content {
      background-color: transparent;
    }
    &:hover {
      box-shadow: 0 rem(4) rem(14) rgba(36, 31, 33, 0.25);
    }
  }

  &--fulled {
    border: rem(1) solid transparent;
    transition: border-color $default-transition;
    &:hover {
      border-color: $light-green;
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .card {
    max-width: calc(50% - #{rem(18)});
  }
}
@media only screen and (max-width: $breakpoint-large-mobile) {
  .card {
    max-width: 100%;
  }
}
</style>
