<template>
  <div class="price">
    <span class="price__sum">1690 руб.</span>
    <CButton class="price__sbt">Купить</CButton>
  </div>
</template>

<script>
import CButton from '@/components/common/CButton'

export default {
  name: 'Price',

  components: {
    CButton,
  },
}
</script>

<style lang="scss" scoped>
.price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: rem(12) 0 0 0;
  &__sum {
    margin: 0 rem(6) 0 0;
    color: #000;
    font-weight: 900;
    font-size: rem(21);
    line-height: 1;
  }

  &__sbt {
    margin: 0 0 0 rem(6);
  }
}
</style>
